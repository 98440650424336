import React from "react";
import { FaGithub, FaInstagram, FaLinkedinIn } from "react-icons/fa";
const ContactLeft = () => {
  return (
    <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#115173] to-[#115173] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center">
      <div className="relative h-[300px] overflow-hidden bg-cover bg-[50%] bg-no-repeat">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.9357126144855!2d-79.74736892533573!3d43.649505852672014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b1559337da907%3A0x6ea15b6d130c3d09!2sKingknoll%20Dr%2C%20Brampton%2C%20ON%2C%20Canada!5e0!3m2!1sen!2sus!4v1726701179799!5m2!1sen!2sus"
          width="100%"
          height={480}
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="map"
        ></iframe>
      </div>

      <div className="flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-white">Nehanshu Nai</h3>
        <p className="text-lg text-gray-400 font-normal">Software Engineer</p>
        <p className="text-lightText text-gray-400 tracking-wide">
          {
            "I’m always open to learning something new and growing as a person. If you’d like to connect, feel free to reach out. I’d love to chat about new opportunities and challenges!"
          }
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Email:{" "}
          <span className="text-lightText">nehanshucnai321@gmail.com</span>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-base uppercase font-titleFont mb-4">Find me in</h2>
        <div className="flex gap-4">
          <span className="bannerIcon">
            <a
              href="https://www.linkedin.com/in/naine312/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn />
            </a>
          </span>
          <span className="bannerIcon">
            <a
              href="https://github.com/naine312"
              target="_blank"
              rel="noreferrer"
            >
              <FaGithub />
            </a>
          </span>
          <span className="bannerIcon">
            <a
              href="https://www.instagram.com/nehanshu6378/"
              rel="noreferrer"
              target="_blank"
            >
              <FaInstagram />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactLeft;
