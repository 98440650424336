import React from "react";
import Layouts from "../Layouts/Layouts";
import ProjectsCard from "./ProjectsCard";
import P1 from "../../assets/images/Project1.png";
import P2 from "../../assets/images/Project2.png";
import P3 from "../../assets/images/Project3.png";
import P4 from "../../assets/images/Project4.png";
import P5 from "../../assets/images/Project5.png";
import P6 from "../../assets/images/Project6.png";

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Layouts
          title="Explore my projects-feedback welcome!"
          des="My Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Artist Registration"
          des="In this Artists Registration project, I created a system for users to manage artist details securely. It protects user information and prevents unauthorized access."
          link="https://github.com/naine312/SpringBoot_project"
          src={P1}
        />
        <ProjectsCard
          title="Courses App"
          des="In this project, I created an interactive app where users can easily add, delete, and update courses. The design provides a smooth and engaging experience for managing course information efficiently."
          link="https://github.com/naine312/course-app"
          src={P2}
        />
        <ProjectsCard
          title="Social Media App"
          des="This social media app is built with React, using advanced features to create a dynamic user experience. Users can add, update, and delete posts, making the platform interactive and easy to use."
          link="https://github.com/naine312/react-social-media-app"
          src={P3}
        />
        <ProjectsCard
          title="Todo APP"
          des="This is a todo app built with React, allowing users to add, edit, and delete tasks. It features a clean, simple interface, making task management easy and effective."
          link="https://github.com/naine312/react-todo-app"
          src={P4}
        />
        <ProjectsCard
          title="Myntra Clone"
          des="In this project, I created a functional clone of Myntra with basic features like product listings. It provides a simple and straightforward shopping experience similar to the original platform."
          link="https://github.com/naine312/Myntra-Clone"
          src={P5}
        />
        <ProjectsCard
          title="Home Automation System"
          des="In this project, I developed a Home Automation System GUI that lets users set alarms for daily tasks, like running a washing machine and controlling lights. The interface is easy to use, helping users manage their home appliances efficiently."
          link="https://github.com/naine312/HAS"
          src={P6}
        />
      </div>
    </section>
  );
};

export default Projects;
