import React from "react";
import NehanshuImg from "../../assets/images/613024d0-c7e1-4132-aa67-1dc39f07be31-removebg.png";

const RightBanner = () => {
  return (
    <div className="w-full lgl:w-1/2 flex justify-center items-center relative">
      <img
        className="w-[300px] h-[400px] lgl:w-[500px] lgl:h-[680px] z-10"
        src={NehanshuImg}
        alt="NEHANSHU"
      />
      <div className="absolute bottom-0 w-[350px] h-[300px] lgl:w-[500px] lgl:h-[500px] bg-gradient-to-r from-[azure] to-[azure] shadow-shadowOne flex justify-center items-center"></div>
    </div>
  );
};

export default RightBanner;
