import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import Resume from "../../assets/file/Nehanshu_Nai_Resume (7).pdf";
import Media from "./Media";
const LeftBanner = () => {
  const [text] = useTypewriter({
    words: ["a Software Engineer."],
    loop: true,
    typeSpeed: 20,
    deleteSpeed: 10,
    delaySpeed: 2000,
  });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h4 className=" text-lg font-normal">WELCOME TO MY PORTFOLIO</h4>
        <h1 className="text-5xl font-bold text-white">
          Hi, I'm{" "}
          <span className="text-designColor capitalize">Nehanshu Nai</span>
        </h1>
        <h2 className="text-xl mdl:text-4xl font-bold text-white">
          <span>And I am {text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#ff014f"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
          So far, my journey in software development has included an internship
          at Skill Squirrel, where I worked on MERN. For more details on my
          experience, you can check out my resume below! In my free time, I
          enjoy playing cricket, traveling, and writing poetry. I also love
          contributing to the community.I'm always excited to learn new things.
          <br />
          Currently, I'm seeking internship opportunities for Summer 2025.
        </p>
        <div>
          <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
              <a href={Resume} target="_blank" rel="noreferrer">
                Resume
              </a>
            </span>
          </button>
        </div>
      </div>
      {/* Media */}
      <Media />
    </div>
  );
};

export default LeftBanner;
