import React from "react";
import {
  FaLinkedinIn,
  FaJava,
  FaPython,
  FaJs,
  FaInstagram,
} from "react-icons/fa";
import { FaGithub } from "react-icons/fa6";
import {
  SiExpress,
  SiMongodb,
  SiMysql,
  SiNodedotjs,
  SiReact,
} from "react-icons/si";
const Media = () => {
  return (
    <div className="flex flex-col xl:flex-col gap-6 lgl:gap-12 justify-between">
      <div>
        <h2 className="text-base uppercase font-titleFont mb-4">Find me in</h2>
        <div className="flex gap-4">
          <span className="bannerIcon">
            <a
              href="https://www.linkedin.com/in/naine312/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn />
            </a>
          </span>
          <span className="bannerIcon">
            <a
              href="https://github.com/naine312"
              target="_blank"
              rel="noreferrer"
            >
              <FaGithub />
            </a>
          </span>
          <span className="bannerIcon">
            <a
              href="https://www.instagram.com/nehanshu6378/"
              rel="noreferrer"
              target="_blank"
            >
              <FaInstagram />
            </a>
          </span>
        </div>
      </div>
      <div>
        <h2 className="text-base uppercase font-titleFont mb-4 ">
          BEST SKILL ON
        </h2>
        <div className="flex flex-wrap gap-4">
          <span className="bannerIcon">
            <FaJava />
          </span>
          <span className="bannerIcon">
            <FaPython />
          </span>
          <span className="bannerIcon">
            <FaJs />
          </span>
          <span className="bannerIcon">
            <SiMysql />
          </span>
          <span className="bannerIcon">
            <SiMongodb />
          </span>
          <span className="bannerIcon">
            <SiExpress />
          </span>
          <span className="bannerIcon">
            <SiReact />
          </span>
          <span className="bannerIcon">
            <SiNodedotjs />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Media;
